
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header {
	width:100%;
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;	
}

.header {
	background-color: #0A0A0A;
	&-content {
		display: flex;
	}
	&-main {
		padding-left: 1.9rem;
		padding-right: 1.9rem;
		display: flex;
		align-items: center;
		width: 50%;
	}
	&-logo {
		padding-top: 1.3rem;
		padding-bottom: 1.1rem;
		min-width: 3.1rem;
	}
	&-nav {
		display: flex;
		margin-left: 1.2rem;
		display: none;
		&.active {
			display: flex;
		}
		li {
		    margin: 0 1.2rem;
			a {
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.2rem;
				text-indent: .1rem;
				color: #9D9B9B;
			}
			&.active, &:hover {
				a {
					text-shadow: 0 0 .1rem #9D9B9B;
				}
			}
		}
	}
	&-info {
		display: flex;
		align-items: stretch;
		width: 50%;
		position: relative;
	}
	&-crumbs {
		background: #3F3F3F;
		padding: 0 2rem;
		display: flex;
		align-items: center;
		width: 50%;
	}
	&-opt {
		display: flex;
		align-items: stretch;
		width: 50%;
		&__col {
			width: 25%;
			display: flex;
			align-items: center;
			justify-content: center;
			&:nth-of-type(even) {
				background: #3F3F3F;
			}
			a {
				i {
					font-size: 1.5rem;
					&:before {
						color: #fff;
					}
					&.icon-close {
						display: none;
					}
				}
				&:hover {
					opacity: .7;
				}
			}
		}
	}
	&-search {
		position: absolute;
		left: 0;
		top: calc(100% - 1px);
		width: 75%;
		transform: translateY(15px);
		transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
		opacity: 0;
		pointer-events: none;
		z-index: 100;
		&.active {
			transform: none;
			opacity: 1;
			pointer-events: all;
		}
		.icon-search {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 1.5rem;
			cursor: pointer;
			font-size: 1.4rem;
			transition: $transition;
			&:hover {
				opacity: .7;
			}
		}
		.box-field .form-control {
			padding-right: 3rem;
		}
	}
}

.toggle-nav {
	&.active {
		i {
			min-width: 1.6rem;
		}
		i.icon-menu {
			display: none;
		}
		i.icon-close {
			display: block;
		}
	}
}

.bread-crumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	li {
		font-weight: 400;
		font-size: .9rem;
		line-height: 1.1rem;
		text-indent: .1rem;
		color: #FFFFFF;
		position: relative;
		margin-right: .7rem;
		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-55%) rotateX(180deg);
		    right: -.6rem;
			content: "/";
		}
		a {
			color: #FFFFFF;
			display: block;
			&:hover {
				text-shadow: 0 0 .1rem #FFFFFF;
			}
		}
		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}
}

// media

@media screen and (max-width: 1400px) {
	.header-nav li {
		margin: 0 0.5rem;
	}
}

@media screen and (max-width: 1200px) {
	.header-nav {
		margin-left: .8rem;
	}
	.header-main {
		padding-left: 0;
	}
	.header-nav li a {
		font-size: .85rem;
	}
}

@media screen and (max-width: 991px) {
	.header-content {
		flex-wrap: wrap;
	}
	.header-main {
		width: 100%;
		justify-content: space-between;
		padding-left: 0;
		padding-right: 0;
	}
	.header-info {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		flex-wrap: wrap;
	}
	.header-nav li {
		margin-right: 0;
		margin-left: 1.6rem;
	}
	.header-crumbs {
		padding-left: 0;
		padding-right: 0;
		background-color: transparent;
	}
	.header-opt {
		justify-content: flex-end;
	}
	.header-opt__col {
		width: 4rem;
		height: 4rem;
	}
	.header-search {
		width: 100%;
	}
	.bread-crumbs li:after {
		transform: translateY(-35%) rotateX(180deg);
	}
	.header-nav li a {
		font-size: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.header-crumbs {
		width: 100%;
		padding: 0 0 1.5rem;
	}
	.header-opt {
		position: absolute;
		right: 1.5rem;
		top: 0;
	}
	.header-opt__col {
		height: 6.4rem;
		width: auto;
		margin-left: 1.5rem;
	}
	.header-nav {
		overflow: auto;
		left: calc(-100% - 1.5rem);
		position: fixed;
		top: 0;
		margin: 0;
		width: calc(100% - 4.3rem);
		height: 100%;
		transition: $transition;
		z-index: 200;
		background-color: #0A0A0A;
		padding: 2.2rem 1.5rem;
		display: flex;
		flex-direction: column;
		li {
			margin: 0;
			margin-bottom: 1.5rem;
		}
		&.active {
			left: 0;
		}
	}
	.header-opt__col:nth-of-type(even) {
		background-color: transparent;
	}
	.header-nav li a {
		font-size: 1.2rem;
	}
	
	.bread-crumbs li:after {
		transform: translateY(-50%) rotateX(180deg);
	}
	.filters-block ul li a i {
		top: .4rem;
	}
	.header-info {
		position: initial;
	}
}
