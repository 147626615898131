/* reset */
/* main variables */
body { margin: 0px; padding: 0px; font-family: "Roboto", sans-serif; background-color: #FFF; height: 100%; line-height: normal; position: relative; font-size: 1rem !important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; font-size: 13px; }

input, textarea { color: #333; font-family: "Roboto", sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #100da9; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -ms-transition: all 0.3s linear; -o-transition: all 0.3s linear; transition: all 0.3s linear; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

ul, li { list-style: none; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular.eot"); src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); font-weight: 400; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Bold.eot"); src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"); font-weight: 700; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon/icomoon.eot?1cf6lm"); src: url("../fonts/icomoon/icomoon.eot?1cf6lm#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?1cf6lm") format("truetype"), url("../fonts/icomoon/icomoon.woff?1cf6lm") format("woff"), url("../fonts/icomoon/icomoon.svg?1cf6lm#icomoon") format("svg"); font-weight: normal; font-style: normal; font-display: block; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: never; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-close2:before { content: "\e90a"; }

.icon-like:before { content: "\e909"; color: #8f8f8f; }

.icon-arrow-left:before { content: "\e908"; }

.icon-menu:before { content: "\e900"; }

.icon-arrow:before { content: "\e904"; }

.icon-balance:before { content: "\e901"; color: #949494; }

.icon-cart:before { content: "\e902"; }

.icon-close:before { content: "\e903"; color: #fff; }

.icon-search:before { content: "\e905"; }

.icon-theme:before { content: "\e906"; }

.icon-user:before { content: "\e907"; }

/* checkbox */
/* radio */
/* select */
.jq-checkbox { top: -1px; width: 13px; height: 13px; border: 1px solid #C3C3C3; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; background: #F6F6F6; box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); vertical-align: middle; cursor: pointer; }

.jq-checkbox.checked .jq-checkbox__div { width: 9px; height: 9px; margin: 2px 0 0 2px; background: #666; box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); }

.jq-checkbox.disabled { opacity: 0.5; }

.jq-radio { width: 12px; height: 12px; border: 1px solid #C3C3C3; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; background: #F6F6F6; box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); -moz-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); -webkit-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); vertical-align: middle; cursor: pointer; }

.jq-radio.checked .jq-radio__div { width: 6px; height: 6px; margin: 3px 0 0 3px; background: #666; }

.jq-radio.disabled { opacity: 0.5; }

.jq-file { width: 270px; box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); border-radius: 4px; }

.jq-file input { cursor: pointer; height: auto; line-height: 1em; }

.jq-file__name { -moz-box-sizing: border-box; box-sizing: border-box; width: 100%; height: 32px; padding: 0 80px 0 10px; color: #333; font: 14px/30px Arial, sans-serif; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; border: 1px solid #CCC; border-bottom-color: #B3B3B3; border-radius: 4px; box-shadow: inset 1px 1px #F1F1F1; background: #FFF; }

.focused .jq-file__name { border: 1px solid #5794BF; }

.jq-file__browse { position: absolute; top: 1px; right: 1px; padding: 0 10px; border-left: 1px solid #CCC; border-radius: 0 4px 4px 0; background: #F1F1F1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAIAAABi9+OQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi+v//PxM2zMDAQEtxgAADAF2uTe9L4getAAAAAElFTkSuQmCC) repeat-x; box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1); color: #333; text-shadow: 1px 1px #FFF; font: 14px/30px Arial, sans-serif; }

.jq-file:hover .jq-file__browse { background-color: #E6E6E6; background-position: 0 -10px; }

.jq-file:active .jq-file__browse { background: #F5F5F5; box-shadow: inset 1px 1px 3px #DDD; }

.jq-file.disabled .jq-file__name { color: #888; }

.jq-file.disabled, .jq-file.disabled .jq-file__browse { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-selectbox { vertical-align: middle; cursor: pointer; width: 100%; }

.jq-selectbox__select { height: 40px; padding: 10px 40px 0 15px; border: 1px solid #C3C3C3; border-radius: 3px; background: #F5F5F5; box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0); font-size: 14px; line-height: 18px; color: #000; font-family: Arial, sans-serif; }

.jq-selectbox__select:hover { background: #F5F5F5; }

.jq-selectbox__select:active { background: #F5F5F5; }

.jq-selectbox.focused .jq-selectbox__select { background: #F5F5F5; }

.opened .jq-selectbox__select { border-color: #000; }

.jq-selectbox.disabled .jq-selectbox__select { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-selectbox__select-text { display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.jq-selectbox__trigger { position: absolute; top: 0; right: 0; width: 34px; height: 100%; }

.jq-selectbox__trigger-arrow { position: absolute; top: 14px; right: 12px; width: 0; height: 0; overflow: hidden; border-top: 5px solid #000; border-right: 5px solid transparent; border-left: 5px solid transparent; background: none; }

.jq-selectbox__dropdown { top: 40px; width: 100%; -moz-box-sizing: border-box; box-sizing: border-box; margin: 0; padding: 0; border: 1px solid #000; border-radius: 4px; background: #FFF; box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); -webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); }

.jq-selectbox__search { margin: 5px; }

.jq-selectbox__search input { -moz-box-sizing: border-box; box-sizing: border-box; width: 100%; margin: 0; padding: 5px 27px 6px 8px; outline: none; border: 1px solid #CCC; border-radius: 3px; background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%; box-shadow: inset 1px 1px #F1F1F1; color: #333; -webkit-appearance: textfield; }

.jq-selectbox__search input::-webkit-search-cancel-button, .jq-selectbox__search input::-webkit-search-decoration { -webkit-appearance: none; }

.jq-selectbox__not-found { margin: 5px; padding: 5px 8px 6px; background: #F0F0F0; font-size: 13px; }

.jq-selectbox ul { margin: 0; padding: 0; }

.jq-selectbox li { min-height: 18px; padding: 5px 10px 5px 10px; color: #000; font-size: 14px; line-height: 18px; font-family: Arial, sans-serif; }

.jq-selectbox li.selected { background-color: #000; color: #FFF; }

.jq-selectbox li:hover { background-color: #000; color: #FFF; }

.jq-selectbox li.disabled { color: #ccc; background: none; }

.jq-selectbox li.disabled:hover { background: none; }

.jq-selectbox li.optgroup { font-weight: bold; }

.jq-selectbox li.optgroup:hover { background: none; color: #231F20; cursor: default; }

.jq-selectbox li.option { padding-left: 25px; }

.jq-select-multiple { -moz-box-sizing: border-box; box-sizing: border-box; padding: 1px; border: 1px solid #CCC; border-bottom-color: #B3B3B3; border-radius: 4px; box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1); background: #FFF; color: #333; font: 14px/18px Arial, sans-serif; cursor: default; }

.jq-select-multiple.focused { border: 1px solid #5794BF; }

.jq-select-multiple.disabled { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-select-multiple ul { margin: 0; padding: 0; }

.jq-select-multiple li { padding: 3px 9px 4px; list-style: none; }

.jq-select-multiple li:first-child { border-radius: 3px 3px 0 0; }

.jq-select-multiple li:last-child { border-radius: 0 0 3px 3px; }

.jq-select-multiple li.selected { background: #08C; color: #FFF; }

.jq-select-multiple li.disabled { color: #AAA; }

.jq-select-multiple.disabled li.selected, .jq-select-multiple li.selected.disabled { background: #CCC; color: #FFF; }

.jq-select-multiple li.optgroup { font-weight: bold; }

.jq-select-multiple li.option { padding-left: 25px; }

.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp { padding: 0; margin: 0; border: 0; outline: none; vertical-align: top; }

.fancybox-wrap { position: absolute; top: 0; left: 0; transform: translate3d(0, 0, 0); z-index: 8020; }

.fancybox-skin { position: relative; background: #f9f9f9; color: #444; text-shadow: none; border-radius: 0px; overflow: hidden; }

.fancybox-opened { z-index: 8030; }

.fancybox-opened .fancybox-skin { box-shadow: none; }

.fancybox-outer, .fancybox-inner { position: relative; }

.fancybox-inner { overflow: hidden; }

.fancybox-type-iframe .fancybox-inner { -webkit-overflow-scrolling: touch; }

.fancybox-error { color: #444; font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif; margin: 0; padding: 15px; white-space: nowrap; }

.fancybox-image, .fancybox-iframe { display: block; width: 100%; height: 100%; }

.fancybox-image { max-width: 100%; max-height: 100%; }

#fancybox-loading { position: fixed; top: 50%; left: 50%; margin-top: -22px; margin-left: -22px; background-position: 0 -108px; opacity: 0.8; cursor: pointer; z-index: 8060; }

#fancybox-loading div { width: 44px; height: 44px; }

.fancybox-close { position: absolute; top: 12px; right: 12px; width: 30px; height: 30px; cursor: pointer; z-index: 8040; }

.fancybox-close::before { content: "+"; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) rotate(45deg); font-size: 40px; color: rgba(0, 0, 0, 0.54); transition: all 0.3s linear; }

.fancybox-close:hover::before { color: rgba(0, 0, 0, 0.9); }

.fancybox-nav { position: absolute; top: 0; width: 40%; height: 100%; cursor: pointer; text-decoration: none; background: transparent url(blank.gif); /* helps IE */ -webkit-tap-highlight-color: rgba(0, 0, 0, 0); z-index: 8040; }

.fancybox-prev { left: 0; }

.fancybox-next { right: 0; }

.fancybox-nav span { position: absolute; top: 50%; width: 36px; height: 34px; margin-top: -18px; cursor: pointer; z-index: 8040; visibility: hidden; }

.fancybox-prev span { left: 10px; background-position: 0 -36px; }

.fancybox-next span { right: 10px; background-position: 0 -72px; }

.fancybox-nav:hover span { visibility: visible; }

.fancybox-tmp { position: absolute; top: -99999px; left: -99999px; max-width: 99999px; max-height: 99999px; overflow: visible !important; }

/* Overlay helper */
.fancybox-lock { overflow: visible !important; width: auto; }

.fancybox-lock body { overflow: hidden !important; }

.fancybox-overlay { position: absolute; top: 0; left: 0; overflow: hidden; display: none; z-index: 8010; background: rgba(30, 30, 30, 0.9); }

.fancybox-overlay-fixed { position: fixed; bottom: 0; right: 0; }

.fancybox-lock .fancybox-overlay { overflow: auto; overflow-y: scroll; }

/* Title helper */
.fancybox-title { visibility: hidden; position: relative; text-shadow: none; z-index: 8050; }

.fancybox-opened .fancybox-title { visibility: visible; }

.fancybox-title-float-wrap { position: absolute; bottom: 0; right: 50%; margin-bottom: -35px; z-index: 8050; text-align: center; }

.fancybox-title-float-wrap .child { display: inline-block; margin-right: -100%; padding: 2px 20px; background: transparent; /* Fallback for web browsers that doesn't support RGBa */ background: rgba(0, 0, 0, 0.8); border-radius: 15px; text-shadow: 0 1px 2px #222; color: #FFF; font-weight: bold; line-height: 24px; white-space: nowrap; }

.fancybox-title-outside-wrap { position: relative; margin-top: 10px; color: #fff; }

.fancybox-title-inside-wrap { padding-top: 10px; }

.fancybox-title-over-wrap { position: absolute; bottom: 0; left: 0; color: #fff; padding: 10px; background: #000; background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) { #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span { background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div { background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/ } }

@media (max-width: 500px) { .fancybox-close { top: 6px; right: 6px; } }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header { width: 100%; min-width: 320px; text-align: left; order: 1; -webkit-order: 1; }

.header { background-color: #0A0A0A; }

.header-content { display: flex; }

.header-main { padding-left: 1.9rem; padding-right: 1.9rem; display: flex; align-items: center; width: 50%; }

.header-logo { padding-top: 1.3rem; padding-bottom: 1.1rem; min-width: 3.1rem; }

.header-nav { display: flex; margin-left: 1.2rem; display: none; }

.header-nav.active { display: flex; }

.header-nav li { margin: 0 1.2rem; }

.header-nav li a { font-weight: 400; font-size: 1rem; line-height: 1.2rem; text-indent: .1rem; color: #9D9B9B; }

.header-nav li.active a, .header-nav li:hover a { text-shadow: 0 0 .1rem #9D9B9B; }

.header-info { display: flex; align-items: stretch; width: 50%; position: relative; }

.header-crumbs { background: #3F3F3F; padding: 0 2rem; display: flex; align-items: center; width: 50%; }

.header-opt { display: flex; align-items: stretch; width: 50%; }

.header-opt__col { width: 25%; display: flex; align-items: center; justify-content: center; }

.header-opt__col:nth-of-type(even) { background: #3F3F3F; }

.header-opt__col a i { font-size: 1.5rem; }

.header-opt__col a i:before { color: #fff; }

.header-opt__col a i.icon-close { display: none; }

.header-opt__col a:hover { opacity: .7; }

.header-search { position: absolute; left: 0; top: calc(100% - 1px); width: 75%; transform: translateY(15px); transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); opacity: 0; pointer-events: none; z-index: 100; }

.header-search.active { transform: none; opacity: 1; pointer-events: all; }

.header-search .icon-search { position: absolute; top: 50%; transform: translateY(-50%); right: 1.5rem; cursor: pointer; font-size: 1.4rem; transition: all 0.3s linear; }

.header-search .icon-search:hover { opacity: .7; }

.header-search .box-field .form-control { padding-right: 3rem; }

.toggle-nav.active i { min-width: 1.6rem; }

.toggle-nav.active i.icon-menu { display: none; }

.toggle-nav.active i.icon-close { display: block; }

.bread-crumbs { display: flex; align-items: center; flex-wrap: wrap; }

.bread-crumbs li { font-weight: 400; font-size: .9rem; line-height: 1.1rem; text-indent: .1rem; color: #FFFFFF; position: relative; margin-right: .7rem; }

.bread-crumbs li:after { position: absolute; top: 50%; transform: translateY(-55%) rotateX(180deg); right: -.6rem; content: "/"; }

.bread-crumbs li a { color: #FFFFFF; display: block; }

.bread-crumbs li a:hover { text-shadow: 0 0 .1rem #FFFFFF; }

.bread-crumbs li:last-of-type:after { display: none; }

@media screen and (max-width: 1400px) { .header-nav li { margin: 0 0.5rem; } }

@media screen and (max-width: 1200px) { .header-nav { margin-left: .8rem; }
  .header-main { padding-left: 0; }
  .header-nav li a { font-size: .85rem; } }

@media screen and (max-width: 991px) { .header-content { flex-wrap: wrap; }
  .header-main { width: 100%; justify-content: space-between; padding-left: 0; padding-right: 0; }
  .header-info { width: 100%; padding-left: 0; padding-right: 0; flex-wrap: wrap; }
  .header-nav li { margin-right: 0; margin-left: 1.6rem; }
  .header-crumbs { padding-left: 0; padding-right: 0; background-color: transparent; }
  .header-opt { justify-content: flex-end; }
  .header-opt__col { width: 4rem; height: 4rem; }
  .header-search { width: 100%; }
  .bread-crumbs li:after { transform: translateY(-35%) rotateX(180deg); }
  .header-nav li a { font-size: 1rem; } }

@media screen and (max-width: 767px) { .header-crumbs { width: 100%; padding: 0 0 1.5rem; }
  .header-opt { position: absolute; right: 1.5rem; top: 0; }
  .header-opt__col { height: 6.4rem; width: auto; margin-left: 1.5rem; }
  .header-nav { overflow: auto; left: calc(-100% - 1.5rem); position: fixed; top: 0; margin: 0; width: calc(100% - 4.3rem); height: 100%; transition: all 0.3s linear; z-index: 200; background-color: #0A0A0A; padding: 2.2rem 1.5rem; display: flex; flex-direction: column; }
  .header-nav li { margin: 0; margin-bottom: 1.5rem; }
  .header-nav.active { left: 0; }
  .header-opt__col:nth-of-type(even) { background-color: transparent; }
  .header-nav li a { font-size: 1.2rem; }
  .bread-crumbs li:after { transform: translateY(-50%) rotateX(180deg); }
  .filters-block ul li a i { top: .4rem; }
  .header-info { position: initial; } }

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer { text-align: left; width: 100%; min-width: 320px; order: 3; -webkit-order: 3; }

.footer { position: relative; z-index: 1; padding: 2.3rem 0; }

.footer:before, .footer:after { width: 50%; position: absolute; left: 0; top: 0; height: 100%; content: ''; z-index: -1; }

.footer:before { background: #373737; }

.footer:after { left: auto; right: 0; background: #9A9A9A; }

.footer-content { display: flex; }

.footer-left { width: 50%; padding-right: 4.4rem; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.footer-right { width: 50%; padding-left: 3.1rem; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.footer-copy { font-size: 1rem; line-height: 1.2rem; text-indent: .1rem; color: #D1D1D1; display: flex; align-items: center; }

.footer-copy span { margin-right: 1.6rem; display: flex; align-items: center; color: #D1D1D1; }

.footer-copy span:first-of-type:before { content: url(../img/icons/copy.svg); margin-right: .3rem; margin-top: .3rem; }

.footer-nav { display: flex; align-items: center; }

.footer-nav li { margin-left: 1.4rem; }

.footer-nav li a { font-size: .8rem; line-height: .9rem; text-indent: .1rem; color: #C0C0C0; }

.footer-nav li a:hover { color: #fff; }

.footer-social { display: flex; align-items: center; }

.footer-social li { margin-right: 0.6rem; }

.footer-social li a { font-size: .8rem; line-height: .9rem; text-indent: .1rem; color: #E4E4E4; }

.footer-social li a:hover { color: #fff; }

.footer-lang { margin-left: auto; position: relative; min-width: 3.3rem; margin-right: 1rem; margin-top: .3rem; }

.footer-lang span { display: flex; align-items: center; font-size: .8rem; line-height: .9rem; text-indent: .1rem; color: #E4E4E4; cursor: pointer; }

.footer-lang span i { font-size: .5rem; margin-left: .2rem; transition: all 0.3s linear; }

.footer-lang span i:before { color: #E4E4E4; }

.footer-lang span.active i { transform: rotateX(180deg); }

.footer-lang__box { position: absolute; background: #FFFFFF; box-shadow: 0 0.5rem 1.2rem rgba(65, 84, 112, 0.2); border-radius: .3rem; left: 50%; transform: translateX(-50%) translateY(-1rem); bottom: calc(100% + .5rem); padding: 0.5rem 1rem; transition: all 0.3s linear; opacity: 0; visibility: hidden; width: auto; }

.footer-lang__box.active { opacity: 1; visibility: visible; transform: translateX(-50%) translateY(0); z-index: 1; }

.footer-lang__box li { cursor: pointer; transition: all 0.3s linear; padding: .5rem 0; text-align: center; font-size: .8rem; line-height: .9rem; }

.footer-lang__box li:hover { opacity: .7; }

.footer-cards { display: flex; align-items: center; }

.footer-cards li { margin-left: 1.4rem; }

.footer-cards li a { font-size: .8rem; line-height: .9rem; text-indent: .1rem; color: #E4E4E4; }

.footer-cards li a:hover { color: #fff; }

@media screen and (max-width: 1400px) { .footer-copy span { margin-right: 1rem; }
  .footer-nav li { margin-left: 1rem; }
  .footer-left { padding-right: 1.5rem; }
  .footer-right { padding-left: 1.5rem; }
  .footer-social li { margin-right: 1rem; }
  .footer-cards li { margin-left: 1rem; } }

@media screen and (max-width: 1200px) { .footer-nav { margin-top: 1rem; }
  .footer-nav li { margin-left: 0; margin-right: 1.5rem; }
  .footer-social { width: 100%; justify-content: flex-end; margin-bottom: 1rem; }
  .footer-social li { margin-right: 0; margin-left: 1rem; }
  .footer-copy { width: 100%; }
  .footer-nav li a, .footer-social li a, .footer-cards li a, .footer-lang span, .footer-lang__box li { font-size: 1rem; } }

@media screen and (max-width: 767px) { .footer-nav li { margin-left: 0; margin-right: 0.5rem; } }

@media screen and (max-width: 560px) { .footer { padding: 1.5rem 0; background: #373737; }
  .footer:before, .footer:after { display: none; }
  .footer-content { flex-direction: column; }
  .footer-left, .footer-right { width: 100%; padding: 0; justify-content: center; flex-direction: column; }
  .footer-copy { flex-direction: column; }
  .footer-copy span { margin: 0; margin-bottom: 1rem; }
  .footer-nav { flex-direction: column; margin-bottom: 1rem; }
  .footer-nav li { margin: 0; margin-bottom: 1rem; }
  .footer-social { flex-direction: column; }
  .footer-social li { margin: 0; margin-bottom: 1rem; }
  .footer-lang { margin: 0; margin-bottom: 1rem; }
  .footer-cards { flex-direction: column; }
  .footer-cards li { margin: 0; margin-bottom: 1rem; }
  .footer-cards li:last-of-type { margin-bottom: 0; } }

.main-wrapper { padding: 0 0 0 0; min-width: 320px; width: 100%; position: relative; overflow: hidden; min-height: 100%; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-direction: column; -webkit-flex-direction: column; -ms-flex-direction: column; justify-content: flex-start; -webkit-justify-content: flex-start; -ms-flex-pack: start; }

.wrapper { min-width: 320px; max-width: 111.7rem; padding: 0 4rem 0 4rem; margin: 0 auto; position: relative; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

/* loader */
.loaded .main-wrapper { visibility: hidden; }

.icon-load { background: url(../img/loader.gif) no-repeat left top; width: 40px; height: 40px; position: fixed; left: 50%; top: 50%; margin-left: -20px; margin-left: -20px; display: none; }

.loaded .icon-load { display: block; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { min-width: 320px; text-align: left; width: 100%; order: 2; -webkit-order: 2; flex-grow: 1; -webkit-flex-grow: 1; }

.filters { position: relative; }

.filters.active .filters-block { opacity: 1; pointer-events: all; }

.filters.active .filters-col:nth-of-type(3) { display: none; }

.filters-block { position: absolute; left: 4rem; top: 0; background: #F4F4F4; z-index: 1; width: calc(25% - 2rem); padding: 2.2rem 1.9rem; overflow: auto; height: 100vh; -ms-overflow-style: none; scrollbar-width: none; opacity: 0; pointer-events: none; transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1); }

.filters-block::-webkit-scrollbar { display: none; }

.filters-block__close { top: 2.2rem; right: 1.5rem; position: absolute; cursor: pointer; transition: all 0.3s linear; }

.filters-block__close:hover { opacity: .7; }

.filters-block__close i:before { color: #000000; }

.filters-block ul li { margin-bottom: .9rem; }

.filters-block ul li a { font-size: 1.2rem; line-height: .3rem; color: #4F4F4F; position: relative; }

.filters-block ul li a i { position: absolute; left: -1.1rem; top: .3rem; font-size: .5rem; }

.filters-block ul li a:hover { opacity: .7; }

.filters-block ul li > ul { padding-top: 1.6rem; padding-bottom: 1rem; display: none; }

.filters-block ul li > ul li { margin-bottom: 1.2rem; }

.filters-block ul li > ul li a { font-size: 1rem; color: #878787; }

.filters-block ul li > ul li:last-of-type { margin-bottom: 0; }

.filters-content { display: flex; justify-content: flex-end; }

.filters-col { width: 25%; padding: 2.6rem 2.5rem; display: flex; align-items: center; justify-content: space-between; }

.filters-col:nth-of-type(1) { padding-left: 1.5rem; }

.filters-col:nth-of-type(4) { padding-right: 1.5rem; }

.filters-col span { font-size: 1rem; line-height: 1.2rem; text-indent: .1rem; color: #000000; }

.filters-col a { font-size: 1rem; line-height: 1.2rem; text-indent: .1rem; color: #000000; position: relative; }

.filters-col a:hover { opacity: .7; }

.filters-col a i.icon-arrow { margin-left: .3rem; font-size: .6rem; }

.filters-col a i.icon-arrow-left { position: absolute; left: -1.4rem; top: 50%; transform: translateY(-50%); }

.filters-col ul { display: flex; }

.filters-col ul li { margin-left: 1.2rem; }

.products-items { display: flex; flex-wrap: wrap; }

.product-item { width: 25%; padding: 4.4rem 1.6rem 1.2rem; position: relative; }

.product-item__big { width: 50%; }

.product-item__img { display: flex; align-items: center; justify-content: center; height: 16.2rem; }

.product-item__img img { mix-blend-mode: darken; max-height: 100%; transition: all 0.3s linear; }

.product-item__img img:hover { opacity: .7; mix-blend-mode: darken; }

.product-item__img img.fade { opacity: 0; }

.product-item__title { display: block; margin-top: 2.6rem; font-size: 1rem; text-indent: .1rem; color: #000000; max-width: 12.6rem; }

.product-item__title:hover { opacity: .7; }

.product-item__balance { top: .8rem; left: 50%; transform: translateX(-50%); position: absolute; }

.product-item__balance i { font-size: 1.6rem; }

.product-item__balance:hover { opacity: .7; }

.product-item__colors { position: absolute; top: .8rem; left: 1.2rem; }

.product-item__colors li { margin-bottom: .6rem; cursor: pointer; }

.product-item__colors li a { display: block; width: .7rem; height: .7rem; border-radius: 50%; content: ''; position: relative; }

.product-item__colors li a:after { transition: all 0.3s linear; content: ''; border: .1rem solid #000000; position: absolute; left: 0; top: 0; border-radius: 50%; width: 100%; height: 100%; opacity: 0; }

.product-item__colors li.active a { pointer-events: none; }

.product-item__colors li.active a:after { opacity: 1; }

.product .wrapper { max-width: 85.8rem; }

.product-content { display: flex; justify-content: space-between; }

.product-main { width: 66.7%; background: #E9E9E9; position: relative; padding: 4.9rem 3rem 2.5rem; }

.product-main .product-item__colors { top: 2.8rem; left: 2.4rem; z-index: 10; }

.product-main .product-item__colors li { margin-bottom: 0.8rem; }

.product-main .product-item__colors li a { width: 1.1rem; height: 1.1rem; }

.product-main .product-item__colors li a:after { border-color: #CBCBCB; width: calc(100% + .2rem); height: calc(100% + .2rem); left: -.1rem; top: -.1rem; }

.product-main__item { display: flex !important; justify-content: center; align-items: center; min-height: 38.1rem; }

.product-main__item img { mix-blend-mode: darken; max-height: 100%; display: none; }

.product-main__item img.active { display: block; }

.product-main__bottom { display: flex; align-items: flex-end; justify-content: space-between; margin-top: 1.8rem; }

.product-main__actions { display: flex; flex-direction: column; justify-content: center; }

.product-main__rotate { margin-bottom: 1.4rem; text-align: center; }

.product-main__rotate:hover { opacity: .7; }

.product-main__likes { display: flex; align-items: center; }

.product-main__likes span { margin-right: 1rem; }

.product-main__likes .icon { font-size: 1.4rem; }

.product-info { width: 33.3%; display: flex; flex-direction: column; }

.product-info__bottom { margin-top: auto; padding: 2.2rem 2.1rem; display: flex; justify-content: space-between; align-items: center; background: #3F3F3F; }

.product-info__bottom span, .product-info__bottom a { font-size: 1.2rem; line-height: 2.5rem; text-indent: .1rem; color: #FFFFFF; }

.product-info__bottom a:hover { opacity: .7; }

.product-crumbs { background: #F5F5F5; padding: 2.9rem 2rem; }

.product-crumbs .header-crumbs { padding: 0; background-color: transparent; }

.product-crumbs .header-crumbs li { color: #000000; font-size: 1rem; margin-right: .4rem; }

.product-crumbs .header-crumbs li:after { right: -.5rem; transform: translateY(-50%) rotateX(180deg); }

.product-crumbs .header-crumbs li a { color: #000000; font-size: 1rem; margin-top: -.1rem; }

.product-title { display: block; font-size: 1.5rem; line-height: 2.5rem; text-indent: .1rem; padding-left: .6rem; padding-right: 1.7rem; position: relative; min-height: 3.6rem; display: flex; align-items: flex-end; }

.product-title:after { content: ''; position: absolute; right: 0; top: 0; height: 100%; width: 1.7rem; background: #2C2C2C; }

.product-detail { padding: 1.2rem 2.8rem; }

.product-detail li { position: relative; color: #949494; margin-bottom: .14rem; }

.product-detail li span { color: #000000; margin-right: .3rem; }

.product-detail li i.icon-arrow { position: absolute; left: -1.1rem; top: .4rem; font-size: .5rem; }

.product-detail li i.icon-arrow:before { color: #000000; }

.product-detail li span i.icon-arrow { position: relative; left: auto; top: auto; }

.product-detail li span i.icon-arrow:before { color: #DBDBDB; }

.product-detail li a { display: block; color: #DBDBDB; }

.product-detail li a:hover { color: #000000; }

.product-related .wrapper { max-width: 85.8rem; }

.product-related__top { display: flex; }

.product-related__links { width: 66.7%; padding: 2.7rem 1.9rem 1.6rem; padding-right: 1.6rem; display: flex; align-items: center; justify-content: space-between; }

.product-related__links span { font-size: 1.2rem; line-height: 2.5rem; text-indent: .1rem; color: #000000; }

.product-related__links ul { display: flex; }

.product-related__links ul li { margin-left: 0.7rem; }

.product-related__links ul li a { font-size: 1.2rem; line-height: 2.5rem; text-indent: .1rem; color: #949494; }

.product-related__links ul li a.active { color: #000000; }

.product-related__dots { width: 33.3%; background: #F5F5F5; padding: 3.3rem 1.4rem 2.5rem; display: flex; align-items: center; }

.product-related__slider .products-items { display: flex !important; }

.product-related__slider .product-item { width: 33.3%; }

.product-related__slider .product-item__big { width: 66.7%; }

.slick-dots { display: flex; align-items: center; }

.slick-dots li { margin-right: .9rem; display: flex; align-items: center; }

.slick-dots li:last-of-type { margin-right: 0; }

.slick-dots li button { font-size: 0; width: .8rem; height: .8rem; border-radius: 50%; border: 1px solid transparent; background: #C5C5C5; transition: all 0.3s linear; padding: 0; margin: 0; }

.slick-dots li.slick-active button { border-color: #000000; background-color: transparent; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration { display: none; }

.box-field { width: 100%; }

.box-field .form-control { width: 100%; padding: 1rem 1.2rem; border: .1rem solid #4F4F4F; border-radius: .1rem; }

.filters .jq-selectbox { width: auto; }

.filters .jq-selectbox__select { height: auto; padding: 0; background: transparent; border: none; box-shadow: none; font-size: 1rem; line-height: 1.2rem; text-indent: 0.1rem; color: #000000; position: relative; }

.filters .jq-selectbox__trigger { width: auto; right: -1.5rem; }

.filters .jq-selectbox__trigger-arrow { border: none; margin-left: 0.3rem; font-size: .6rem; font-family: 'icomoon'; color: #000; width: auto; height: auto; left: auto; top: auto; right: auto; position: relative; }

.filters .jq-selectbox__trigger-arrow:before { content: "\e904"; }

.filters .jq-selectbox__dropdown { padding: 0; border: none; border-radius: 0.1rem; min-width: 10rem; top: calc(100% + .5rem) !important; }

.filters .jq-selectbox__dropdown ul { flex-direction: column; }

.filters .jq-selectbox__dropdown ul li { margin: 0; font-size: 1rem; line-height: 1.2rem; text-indent: 0.1rem; color: #000000; transition: all 0.3s linear; }

.filters .jq-selectbox__dropdown ul li.sel.selected, .filters .jq-selectbox__dropdown ul li:hover { background-color: #E9E9E9; }

.popup { position: absolute; left: -9999px; top: -9999px; opacity: 0; }

.fancybox-skin::-webkit-scrollbar, .fancybox-inner::-webkit-scrollbar { width: 0; background: transparent; }

.fancybox-skin::-webkit-scrollbar-thumb, .fancybox-inner::-webkit-scrollbar-thumb { background: transparent; }

.fancybox-overlay { background: rgba(58, 87, 107, 0.4); }

.window-open { width: 77.8rem; max-width: 100%; position: relative; background: #FFFFFF; box-shadow: 0px 10px 25px -2px rgba(1, 134, 190, 0.2); border-radius: 0; }

.fancybox-skin { background-color: transparent; overflow: visible; }

.fancybox-close { display: none; }

.product-crumbs { position: relative; }

.popup-close { width: 6.8rem; height: 100%; display: flex; align-items: center; justify-content: center; background: #fff; position: absolute; top: 0; right: 0; font-size: 1.4rem; }

.popup-close:before { content: "\e90a"; font-family: 'icomoon'; color: #060606; transform: translate(-50%, -50%); left: 50%; top: 50%; transition: all 0.3s linear; position: absolute; }

.popup-close:hover:before { opacity: .7; }

@media screen and (max-width: 1200px) { .product-detail li i.icon-arrow { top: 0.3rem; } }

@media screen and (max-width: 991px) { .filters-col { padding: 1.5rem 2.5rem; }
  .product .wrapper, .product-related .wrapper { padding: 0; }
  html { font-size: 10px; } }

@media screen and (max-width: 767px) { .wrapper { padding-left: 1.5rem; padding-right: 1.5rem; }
  .filters-col { width: 50%; }
  .filters-content { flex-wrap: wrap; }
  .filters.active .filters-col:nth-of-type(3) { display: flex; }
  .filters-block { left: 0; width: 50%; position: fixed; }
  .product-item { width: 50%; }
  .filters-col { padding: 1.5rem; }
  .product-content { flex-wrap: wrap; }
  .product-main { width: 100%; padding: 1.5rem; }
  .product-main .product-item__colors { left: 1.5rem; top: 1.5rem; }
  .product-main__item { min-height: auto; height: 25rem; }
  .product-main__bottom { margin-top: 1rem; }
  .product-info { width: 100%; }
  .product-crumbs { padding: 1.5rem; }
  .product-title { padding: 1.5rem; }
  .product-detail { padding: 1.5rem 3rem; padding-top: 0; }
  .product-info__bottom { padding: 1.5rem; }
  .product-related__top { flex-direction: column; }
  .product-related__links { width: 100%; flex-direction: column; align-items: flex-start; padding: 1.5rem; }
  .product-related__dots { width: 100%; }
  .product-related__links ul li { margin-left: 0; margin-right: 0.5rem; }
  .product-related__dots { padding: 1.5rem; justify-content: center; }
  .product-related__slider .product-item { width: 50%; }
  html { font-size: 13px; }
  .filters-block ul li a i { top: .5rem; }
  .product-crumbs .header-crumbs li a { margin-top: -.2rem; }
  .product-crumbs { position: initial; }
  .popup-close { top: 0; right: 0; width: 4rem; height: 4rem; } }

@media screen and (max-width: 560px) { .filters-col { width: 100%; }
  .product-item { width: 100%; }
  .filters-block { width: 100%; }
  .product-related__slider .product-item { width: 100%; } }
