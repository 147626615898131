@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.eot');
	src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), 
	url('../fonts/Roboto-Regular.woff') format('woff'), 
	url('../fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.eot');
	src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), 
	url('../fonts/Roboto-Bold.woff') format('woff'), 
	url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}
@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon/icomoon.eot?1cf6lm');
	src:  url('../fonts/icomoon/icomoon.eot?1cf6lm#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon/icomoon.ttf?1cf6lm') format('truetype'),
	  url('../fonts/icomoon/icomoon.woff?1cf6lm') format('woff'),
	  url('../fonts/icomoon/icomoon.svg?1cf6lm#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-close2:before {
	content: "\e90a";
}
.icon-like:before {
	content: "\e909";
	color: #8f8f8f;
}
.icon-arrow-left:before {
	content: "\e908";
}
.icon-menu:before {
	content: "\e900";
}
.icon-arrow:before {
	content: "\e904";
}
.icon-balance:before {
	content: "\e901";
	color: #949494;
}
.icon-cart:before {
	content: "\e902";
}
.icon-close:before {
	content: "\e903";
	color: #fff;
}
.icon-search:before {
	content: "\e905";
}
.icon-theme:before {
	content: "\e906";
}
.icon-user:before {
	content: "\e907";
}
