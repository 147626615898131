
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer {
	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;	
}
.footer {
	position: relative;
	z-index: 1;
	padding: 2.3rem 0;
	&:before, &:after {
		width: 50%;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		content: '';
		z-index: -1;
	}
	&:before {
		background: #373737;
	}
	&:after {
		left: auto;
		right: 0;
		background: #9A9A9A;
	}
	&-content {
		display: flex;
	}
	&-left {
		width: 50%;
		padding-right: 4.4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	&-right {
		width: 50%;
		padding-left: 3.1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	&-copy {
		font-size: 1rem;
		line-height: 1.2rem;
		text-indent: .1rem;
		color: #D1D1D1;
		display: flex;
		align-items: center;
		span {
		    margin-right: 1.6rem;
			display: flex;
			align-items: center;
			color: #D1D1D1;
			&:first-of-type {
				&:before {
					content: url(../img/icons/copy.svg);
					margin-right: .3rem;
					margin-top: .3rem;
				}
			}
		}
	}
	&-nav {
		display: flex;
		align-items: center;
		li {
			margin-left: 1.4rem;
			a {
				font-size: .8rem;
				line-height: .9rem;
				text-indent: .1rem;
				color: #C0C0C0;
				&:hover {
					color: #fff;
				}
			}
		}
	}
	&-social {
		display: flex;
		align-items: center;
		li {
			margin-right: 0.6rem;
			a {
				font-size: .8rem;
				line-height: .9rem;
				text-indent: .1rem;
				color: #E4E4E4;
				&:hover {
					color: #fff;
				}
			}
		}
	}
	&-lang {
		margin-left: auto;
		position: relative;
		min-width: 3.3rem;
		margin-right: 1rem;
		margin-top: .3rem;
		span {
			display: flex;
			align-items: center;
			font-size: .8rem;
			line-height: .9rem;
			text-indent: .1rem;
			color: #E4E4E4;
			cursor: pointer;
			i {
			    font-size: .5rem;
    			margin-left: .2rem;
				transition: $transition;
				&:before {
					color: #E4E4E4;
				}
			}
			&.active {
				i {
					transform: rotateX(180deg);
				}
			}
		}
		&__box {
			position: absolute;
			background: #FFFFFF;
			box-shadow: 0 .5rem 1.2rem rgba(65, 84, 112, 0.2);
			border-radius: .3rem;
			left: 50%;
		    transform: translateX(-50%) translateY(-1rem);
		    bottom: calc(100% + .5rem);
			padding: 0.5rem 1rem;
			transition: $transition;
			opacity: 0;
			visibility: hidden;
			width: auto;
			&.active {
				opacity: 1;
				visibility: visible;
				transform: translateX(-50%) translateY(0);
				z-index: 1;
			}
			li {
				cursor: pointer;
				transition: $transition;
				padding: .5rem 0;
				text-align: center;
				font-size: .8rem;
				line-height: .9rem;
				&:hover {
					opacity: .7;
				}
			}
		}
	}
	&-cards {
		display: flex;
		align-items: center;
		li {
			margin-left: 1.4rem;
			a {
				font-size: .8rem;
				line-height: .9rem;
				text-indent: .1rem;
				color: #E4E4E4;
				&:hover {
					color: #fff;
				}
			}
		}
	}
}

// media

@media screen and (max-width: 1400px) {
	.footer-copy span {
		margin-right: 1rem;
	}
	.footer-nav li {
		margin-left: 1rem;
	}
	.footer-left {
		padding-right: 1.5rem;
	}
	.footer-right {
		padding-left: 1.5rem;
	}
	.footer-social li {
		margin-right: 1rem;
	}
	.footer-cards li {
		margin-left: 1rem;
	}
}

@media screen and (max-width: 1200px) {
	.footer-nav {
		margin-top: 1rem;
	}
	.footer-nav li {
		margin-left: 0;
		margin-right: 1.5rem;
	}
	.footer-social {
		width: 100%;
		justify-content: flex-end;
		margin-bottom: 1rem;
	}
	.footer-social li {
		margin-right: 0;
		margin-left: 1rem;
	}
	.footer-copy {
		width: 100%;
	}
	.footer-nav li a,
	.footer-social li a,
	.footer-cards li a,
	.footer-lang span,
	.footer-lang__box li {
		font-size: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.footer-nav li {
		margin-left: 0;
		margin-right: 0.5rem;
	}
}
@media screen and (max-width: 560px) {
	.footer {
		padding: 1.5rem 0;
		background: #373737;
		&:before, &:after {
			display: none;
		}
	}
	.footer-content {
		flex-direction: column;
	}
	.footer-left, .footer-right {
		width: 100%;
		padding: 0;
		justify-content: center;
		flex-direction: column;
	}
	.footer-copy {
		flex-direction: column;
	}
	.footer-copy span {
		margin: 0;
		margin-bottom: 1rem;
	}
	.footer-nav {
		flex-direction: column;
		margin-bottom: 1rem;
	}
	.footer-nav li {
		margin: 0;
		margin-bottom: 1rem;
	}
	.footer-social {
		flex-direction: column;
	}
	.footer-social li {
		margin: 0;
		margin-bottom: 1rem;
	}
	.footer-lang {
		margin: 0;
		margin-bottom: 1rem;
	}
	.footer-cards {
		flex-direction: column;
	}
	.footer-cards li {
		margin: 0;
		margin-bottom: 1rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}