
//main styles

.main-wrapper{
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}
.wrapper{
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 4rem 0 4rem;
	margin: 0 auto;
	position: relative;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


		/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{background:url(../img/loader.gif) no-repeat left top;width:40px;height:40px;position:fixed;left:50%;top:50%;margin-left:-20px;margin-left:-20px;display:none;}
.loaded .icon-load{display:block;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {	
	min-width: $min-width;
	text-align:left;
    width: 100%;
	order: 2;
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;
}

.filters {
	position: relative;
	&.active {
		.filters-block {
			opacity: 1;
			pointer-events: all;
		}
		.filters-col {
			&:nth-of-type(3) {
				display: none;
			}
		}
	}
	&-block {
		position: absolute;
		left: 4rem;
		top: 0;
		background: #F4F4F4;
		z-index: 1;
		width: calc(25% - 2rem);
		padding: 2.2rem 1.9rem;
		overflow: auto;
		height: 100vh;
		-ms-overflow-style: none;
		scrollbar-width: none;
		opacity: 0;
		pointer-events: none;
		transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
		&::-webkit-scrollbar {
			display: none;
		}
		&__close {
			top: 2.2rem;
			right: 1.5rem;
			position: absolute;
			cursor: pointer;
			transition: $transition;
			&:hover {
				opacity: .7;
			}
			i {
				&:before {
					color: #000000;
				}
			}
		}
		ul {
			li {
				margin-bottom: .9rem;
				a {
					font-size: 1.2rem;
					line-height: .3rem;
					color: #4F4F4F;
					position: relative;
					i {
						position: absolute;
						left: -1.1rem;
						top: .3rem;
						font-size: .5rem;
					}
					&:hover {
						opacity: .7;
					}
				}
				& > ul {
				    padding-top: 1.6rem;
					padding-bottom: 1rem;
					display: none;
					li {
						margin-bottom: 1.2rem;
						a {
							font-size: 1rem;
							color: #878787;
						}
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	&-content {
		display: flex;
		justify-content: flex-end;
	}
	&-col {
		width: 25%;
		padding: 2.6rem 2.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:nth-of-type(1) {
			padding-left: 1.5rem;
		}
		&:nth-of-type(4) {
			padding-right: 1.5rem;
		}
		span {
			font-size: 1rem;
			line-height: 1.2rem;
			text-indent: .1rem;
			color: #000000;
		}
		a {
			font-size: 1rem;
			line-height: 1.2rem;
			text-indent: .1rem;
			color: #000000;
			position: relative;
			&:hover {
				opacity: .7;
			}
			i.icon-arrow {
				margin-left: .3rem;
				font-size: .6rem;
			}
			i.icon-arrow-left {
				position: absolute;
				left: -1.4rem;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		ul {
			display: flex;
			li {
				margin-left: 1.2rem;
			}
		}
	}
}

.products {
	&-items {
		display: flex;
		flex-wrap: wrap;
	}
}
.product-item {
	width: 25%;
	padding: 4.4rem 1.6rem 1.2rem;
	position: relative;
	&__big {
		width: 50%;
	}
	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 16.2rem;
		img {
			mix-blend-mode: darken;
			max-height: 100%;
			transition: $transition;
			&:hover {
				opacity: .7;
				mix-blend-mode: darken;
			}
			&.fade {
				opacity: 0;
			}
		}
	}
	&__title {
		display: block;
		margin-top: 2.6rem;
		font-size: 1rem;
		text-indent: .1rem;
		color: #000000;
		max-width: 12.6rem;
		&:hover {
			opacity: .7;
		}
	}
	&__balance {
		top: .8rem;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		i {
		    font-size: 1.6rem;
		}
		&:hover {
			opacity: .7;
		}
	}
	&__colors {
		position: absolute;
		top: .8rem;
		left: 1.2rem;
		li {
			margin-bottom: .6rem;
			cursor: pointer;
			a {
				display: block;
				width: .7rem;
				height: .7rem;
				border-radius: 50%;
				content: '';
				position: relative;
				&:after {
					transition: $transition;
					content: '';
					border: .1rem solid #000000;
					position: absolute;
					left: 0;
					top: 0;
					border-radius: 50%;
					width: 100%;
					height: 100%;
					opacity: 0;
				}
			}
			&.active {
				a {
					pointer-events: none;
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}

// product page

.product {
	.wrapper {
		max-width: 85.8rem;
	}
	&-content {
		display: flex;
		justify-content: space-between;
	}
	&-main {
		width: 66.7%;
		background: #E9E9E9;
		position: relative;
		padding: 4.9rem 3rem 2.5rem;
		.product-item__colors {
			top: 2.8rem;
			left: 2.4rem;
			z-index: 10;
			li {
				margin-bottom: 0.8rem;
			}
			li a {
				width: 1.1rem;
				height: 1.1rem;
				&:after {
					border-color: #CBCBCB;
					width: calc(100% + .2rem);
					height: calc(100% + .2rem);
					left: -.1rem;
					top: -.1rem;
				}
			}
		}
		&__item {
			display: flex!important;
			justify-content: center;
			align-items: center;
			min-height: 38.1rem;
			img {
				mix-blend-mode: darken;
				max-height: 100%;
				display: none;
				&.active {
					display: block;
				}
			}
		}
		&__bottom {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-top: 1.8rem;
		}
		&__actions {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&__rotate {
		    margin-bottom: 1.4rem;
			text-align: center;
			&:hover {
				opacity: .7;
			}
		}
		&__likes {
			display: flex;
			align-items: center;
			span {
				margin-right: 1rem;
			}
			.icon {
				font-size: 1.4rem;
			}
		}
	}
	&-info {
		width: 33.3%;
		display: flex;
		flex-direction: column;
		&__bottom {
			margin-top: auto;
			padding: 2.2rem 2.1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #3F3F3F;
			span, a {
				font-size: 1.2rem;
				line-height: 2.5rem;
				text-indent: .1rem;
				color: #FFFFFF;
			}
			a {
				&:hover {
					opacity: .7;
				}
			}
		}
	}
	&-crumbs {
		background: #F5F5F5;
		padding: 2.9rem 2rem;
		.header-crumbs {
			padding: 0;
			background-color: transparent;
			li {
				color: #000000;
				font-size: 1rem;
				margin-right: .4rem;
				&:after {
					right: -.5rem;
					transform: translateY(-50%) rotateX(180deg);
				}
				a {
					color: #000000;
					font-size: 1rem;
					margin-top: -.1rem;
				}
			}
		}
	}
	&-title {
		display: block;
		font-size: 1.5rem;
		line-height: 2.5rem;
		text-indent: .1rem;
		padding-left: .6rem;
		padding-right: 1.7rem;
		position: relative;
		min-height: 3.6rem;
		display: flex;
		align-items: flex-end;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 1.7rem;
			background: #2C2C2C;
		}
	}
	&-detail {
		padding: 1.2rem 2.8rem;
		li {
			position: relative;
			color: #949494;
			margin-bottom: .14rem;
			span {
				color: #000000;
				margin-right: .3rem;
			}
			i.icon-arrow {
				position: absolute;
				left: -1.1rem;
				top: .4rem;
				font-size: .5rem;
				&:before {
					color: #000000;
				}
			}
			span {
				i.icon-arrow {
					position: relative;
					left: auto;
					top: auto;
					&:before {
						color: #DBDBDB;
					}
				}
			}
			a {
				display: block;
				color: #DBDBDB;
				&:hover {
					color: #000000;
				}
			}
		}
	}
	&-related {
		.wrapper {
			max-width: 85.8rem;
		}
		&__top {
			display: flex;
		}
		&__links {
			width: 66.7%;
			padding: 2.7rem 1.9rem 1.6rem;
			padding-right: 1.6rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				font-size: 1.2rem;
				line-height: 2.5rem;
				text-indent: .1rem;
				color: #000000;
			}
			ul {
				display: flex;
				li {
					margin-left: 0.7rem;
					a {
						font-size: 1.2rem;
						line-height: 2.5rem;
						text-indent: .1rem;
						color: #949494;
						&.active {
							color: #000000;
						}
					}
				}
			}
		}
		&__dots {
			width: 33.3%;
			background: #F5F5F5;
		    padding: 3.3rem 1.4rem 2.5rem;
			display: flex;
			align-items: center;
		}
		&__slider {
			.products-items {
				display: flex!important;
			}
			.product-item {
				width: 33.3%;
			}
			.product-item__big {
				width: 66.7%;
			}
		}
	}
}

.slick-dots {
	display: flex;
	align-items: center;
	li {
		margin-right: .9rem;
		display: flex;
		align-items: center;
		&:last-of-type {
			margin-right: 0;
		}
		button {
			font-size: 0;
			width: .8rem;
			height: .8rem;
			border-radius: 50%;
			border: 1px solid transparent;
			background: #C5C5C5;
			transition: $transition;
			padding: 0;
			margin: 0;
		}
		&.slick-active {
			button {
				border-color: #000000;
				background-color: transparent;
			}
		}
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.box-field {
	width: 100%;
	.form-control {
		width: 100%;
		padding: 1rem 1.2rem;
		border: .1rem solid #4F4F4F;
		border-radius: .1rem;
	}
}

.filters {
	.jq-selectbox {
		width: auto;
	}
	.jq-selectbox__select {
		height: auto;
		padding: 0;
		background: transparent;
		border: none;
		box-shadow: none;
		font-size: 1rem;
		line-height: 1.2rem;
		text-indent: 0.1rem;
		color: #000000;
		position: relative;
	}
	.jq-selectbox__trigger {
		width: auto;
		right: -1.5rem;
	}
	.jq-selectbox__trigger-arrow {
		border: none;
		margin-left: 0.3rem;
		font-size: .6rem;
		font-family: 'icomoon';
		color: #000;
		width: auto;
		height: auto;
		left: auto;
		top: auto;
		right: auto;
		position: relative;
		&:before {
			content: "\e904";
		}
	}
	.jq-selectbox__dropdown {
		padding: 0;
		border: none;
		border-radius: 0.1rem;
		min-width: 10rem;
		top: calc(100% + .5rem)!important;
		ul {
			flex-direction: column;
			li {
				margin: 0;
				font-size: 1rem;
				line-height: 1.2rem;
				text-indent: 0.1rem;
				color: #000000;
				transition: $transition;
				&.sel.selected, &:hover {
					background-color: #E9E9E9;
				}
			}
		}
	}
}

// popups

.popup {
	position: absolute;
	left: -9999px;
	top: -9999px;
	opacity: 0;
}
.fancybox-skin, .fancybox-inner {
	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}
.fancybox-overlay {
	background: rgba(58, 87, 107, 0.4);
}
.window-open {
	width: 77.8rem;
	max-width: 100%;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px -2px rgba(1, 134, 190, 0.2);
	border-radius: 0;
}
.fancybox-skin {
	background-color: transparent;
	overflow: visible;
}
.fancybox-close {
	display: none;
}
.product-crumbs {
	position: relative;
}
.popup-close {
	width: 6.8rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.4rem;
	&:before {
		content: "\e90a";
		font-family: 'icomoon';
		color: #060606;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		transition: $transition;
		position: absolute;
	}
	&:hover {
		&:before {
			opacity: .7;
		}
	}
}

// media

@media screen and (max-width: 1200px) {
	.product-detail li i.icon-arrow {
		top: 0.3rem;
	}
}

@media screen and (max-width: 991px) {
	.filters-col {
		padding: 1.5rem 2.5rem;
	}
	.product .wrapper,
	.product-related .wrapper {
		padding: 0;
	}
	html {
		font-size: 10px;
	}
}

@media screen and (max-width: 767px) {
	.wrapper {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.filters-col {
		width: 50%;
	}
	.filters-content {
		flex-wrap: wrap;
	}
	.filters.active .filters-col:nth-of-type(3) {
		display: flex;
	}
	.filters-block {
		left: 0;
		width: 50%;
		position: fixed;
	}
	.product-item {
		width: 50%;
	}
	.filters-col {
		padding: 1.5rem;
		&:nth-of-type(3) {
			// padding-left: 2.9rem;
		}
	}

	.product-content {
		flex-wrap: wrap;
	}
	.product-main {
		width: 100%;
		padding: 1.5rem;
	}
	.product-main .product-item__colors {
		left: 1.5rem;
		top: 1.5rem;
	}
	.product-main__item {
		min-height: auto;
		height: 25rem;
	}
	.product-main__bottom {
		margin-top: 1rem;
	}
	.product-info {
		width: 100%;
	}
	.product-crumbs {
		padding: 1.5rem;
	}
	.product-title {
		padding: 1.5rem;
	}
	.product-detail {
		padding: 1.5rem 3rem;
		padding-top: 0;
	}
	.product-info__bottom {
		padding: 1.5rem;
	}
	.product-related__top {
		flex-direction: column;
	}
	.product-related__links {
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		padding: 1.5rem;
	}
	.product-related__dots {
		width: 100%;
	}
	.product-related__links ul li {
		margin-left: 0;
		margin-right: 0.5rem;
	}
	.product-related__dots {
		padding: 1.5rem;
		justify-content: center;
	}
	.product-related__slider .product-item {
		width: 50%;
	}
	html {
		font-size: 13px;
	}
	.filters-block ul li a i {
		top: .5rem;
	}
	.product-crumbs .header-crumbs li a {
		margin-top: -.2rem;
	}
	.product-crumbs {
		position: initial;
	}
	.popup-close {
		top: 0;
		right: 0;
		width: 4rem;
		height: 4rem;
	}
}

@media screen and (max-width: 560px) {
	.filters-col {
		width: 100%;
	}
	.product-item {
		width: 100%;
	}
	.filters-block {
		width: 100%;
	}
	.product-related__slider .product-item {
		width: 100%;
	}
}

