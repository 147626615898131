/* reset */
$min-width: 320px; 
$max-width: 111.7rem;
$base-bg: #FFF; 
$base-font-size: 1rem; 
$base-line-height: 120%; 
$base-font-family: 'Roboto', sans-serif; 
$custom-font-family: 'Roboto', sans-serif; 
$link-color: #100da9;
$transition: all 0.3s linear;

/* main variables */

//text, titles
$text-color: #000;
$title-color: #000;
$title-font-family: $custom-font-family;

$font-size-h1: 3.6rem;
$line-height-h1: 4.2rem;
$font-size-h2: 3.2rem;
$line-height-h2: 3.7rem;
$font-size-h3: 2.8rem;
$line-height-h3: 3.2rem;